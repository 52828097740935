
// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$WebSite-primary: mat.define-palette(mat.$indigo-palette);
$WebSite-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$WebSite-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$WebSite-theme: mat.define-light-theme((
  color: (
    primary: $WebSite-primary,
    accent: $WebSite-accent,
    warn: $WebSite-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($WebSite-theme);

@import './my-theme';

hr.rainbow{
  height: 2px;
  margin: 0px;
  background: #e8a500;
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,#e8a500), color-stop(25%,#db0026), color-stop(50%,#dd1385), color-stop(75%,#008c76), color-stop(100%,#007aba));
  background: -webkit-linear-gradient(-45deg, #e8a500 0%,#db0026 25%,#dd1385 50%,#008c76 75%,#007aba 100%);
  background: -moz-linear-gradient(-45deg, #e8a500 0%, #db0026 25%, #dd1385 50%, #008c76 75%, #007aba 100%);
  background: -o-linear-gradient(-45deg, #e8a500 0%,#db0026 25%,#dd1385 50%,#008c76 75%,#007aba 100%);
  background: -ms-linear-gradient(-45deg, #e8a500 0%,#db0026 25%,#dd1385 50%,#008c76 75%,#007aba 100%);
  background: linear-gradient(-45deg, #e8a500 0%,#db0026 25%,#dd1385 50%,#008c76 75%,#007aba 100%);
}

html, body {
  height: 100%;
  margin: 0;
  background-color: #fafafa;
}

.wrapper {
  min-height: 100%;
  margin-bottom: -150px;
}

.push {
  height: 150px;
  background-color: #333333;;
}

.herobanner{
  cursor:pointer;
}

.logoacel{
  max-width: 30%;
}
.cdk-overlay-pane.my-dialog {
  position: relative!important;
}
.close.mat-button,.close {
  position: relative;
  top: 0;
  right: 0;
  padding: 5px !important;
  font-size: 15px !important;
  line-height: 14px;
  min-width: auto;
  margin-right:10px;
}

a, a:hover {
  color: mat.get-color-from-palette($my-theme-primary, 900);
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  opacity: 0.8;
}

.logo, .card {
  width: 80%;
  margin: auto;

}

.card-big {
  width: 70%;
  margin: auto;

}

.card, .card-big {
  /* margin-top: 50px; */
}

.logo{
  padding-top: 20px;
}

/* Margin */
.mx-10{
  margin: 0 10px!important;
}
.mx-20{
  margin: 0 20px!important;
}
.mx-30{
  margin: 0 30px!important;
}
.mx-40{
  margin: 0 40px!important;
}
.mx-50{
  margin: 0 50px!important;
}
.my-10{
  margin: 10px 0!important;
}
.my-20{
  margin: 20px 0!important;
}
.my-30{
  margin: 30px 0!important;
}
.my-40{
  margin: 40px 0!important;
}
.my-50{
  margin: 50px 0!important;
}

.m-auto{
  margin: auto!important;
}
.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.ml-10{
  margin-left: 10px;
}


.ml-20 {
  margin-left:  20px;
}

.ml-30{
  margin-left: 30px;
}

.ml-80 {
  margin-left: 80px;
}


.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-30 {
  margin-right: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}
/* padding */
.p-10{
  padding:10px!important;
}
.p-20{
  padding:20px!important;
}
.p-30{
  padding:30px!important;
}
.p-40{
  padding:40px!important;
}
.p-50{
  padding:50px!important;
}
.px-10{
  padding: 0 10px!important;
}
.px-20{
  padding: 0 20px!important;
}
.px-30{
  padding: 0 30px!important;
}
.px-40{
  padding: 0 40px!important;
}
.px-50{
  padding: 0 50px!important;
}
.py-10{
  padding: 10px 0!important;
}
.py-20{
  padding: 20px 0!important;
}
.py-30{
  padding: 30px 0!important;
}
.py-40{
  padding: 40px 0!important;
}
.py-50{
  padding: 50px 0!important;
}

.p-auto{
  padding: auto;
}
.pt-20 {
  padding-top: 20px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-50 {
  padding-top: 50px;
}
.pt-60 {
  padding-top: 60px;
}
.pl-10{
  padding-left: 10px;
}


.pl-20 {
  padding-left:  20px;
}

.pl-30{
  padding-left: 30px;
}

.pl-80 {
  padding-left: 80px;
}


.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-30 {
  padding-right: 30px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-100 {
  padding-bottom: 100px;
}
.pb-0 {
  padding-bottom: 0!important;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-large{
  font-size: 24px;
}

.text-title-stepper {
  > p , label {
    display: block;
    font-weight: normal;
    font-family: 'AvenirNext', sans-serif;
    text-align: left;
    font-size: 18px;
    line-height: 27px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.invalid{
  color:#f44336!important;
}
.float-left{
  float: left;
}
.float-right{
  float: right;
}
/* font */
h1 {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
}
h3, h2 {
  font-weight: bold;
}

p {
  font-size: 18px;
  line-height: 27px;
}


.link-catalogo {
  color: #63666A!important;
  text-transform: uppercase!important;
}

.bold {
  font-weight: bold;
}

.energy-button {
  width: auto;
  /* padding: 0 40px; */
  margin: auto;
  border-radius: 0px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1px;
}


.text-white, .text-white:hover {
  color: #ffffff;
}

.text-top-link, .text-top-link:hover {
  font-size: 12px;
  text-decoration: underline;
  line-height: 44px;
}

.text-top-link:hover {
  opacity: 0.6;

}

.heading-title {
  font-weight: normal;
}

.myapp-no-padding-dialog .mat-dialog-container,
.myapp-no-padding-dialog-login-fb .mat-dialog-container {
  padding: 20px 0px;
  border-radius: 0px;
  text-align: center;
}

.myapp-no-padding-dialog-login-fb {
  overflow: scroll;
}


.myapp-no-padding-dialog-login-fb section#modal-body h3{
  font-size: 12px;
  line-height: 20px;
}
#modal-header {
  border-bottom: 1px solid #d20014;
}
#modal-header h4 {
  text-transform: uppercase;
}

#modal-body .text-block.text-blu.text-center {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
}
#modal-footer {
  margin: 20px 0px;
}
#modal-footer a {
  background-color: #cd0610;
    color: white;
    padding: 10px;
    text-decoration: none !important;
    text-transform: uppercase;
    font-weight: bold;
    border: 3px solid #cd0610;
}
.mat-raised-button.mat-primary {
  text-transform: uppercase !important;
  border: 3px solid #cd0610;
}
.mat-button-disabled,
#modal-footer a.cancel-button{
  background-color: transparent !important;
    border: 2px solid #cd0610 !important;
    color: #cd0610 !important;
    text-transform: uppercase !important;
    cursor: pointer;
    font-family: 'AvenirNext-Bold';
}

.mat-button-disabled:hover {
 opacity: 0.8 !important;
 cursor: pointer;
}

/* footer */
footer .footer-copyright {
  background-color: mat.get-color-from-palette($my-theme-primary, 900);
  color: $light-primary-text;
  padding-top: 50px;
}

.footer-copyright,
.footer-copyright a,
.footer-copyright span {
  font-size: 10px;
}

.footer-sponsor a:hover {
  text-transform: none;
  color: mat.get-color-from-palette($my-theme-primary, 900);
  opacity: 0.6;
}

.text-smaller {
  font-size: 12px;
  line-height: 15px;
}

mat-nav-list.list-horizontal {
  display: inline-block;
  flex-direction: row;
}

.team-images img {
  height: 45px;
  margin: 4px;
}

.powered {
  width: 100%;
  margin-top: 30px;
  display: block;
}

.facebook {
  width: 60%;
  margin: 20px 0;
  display: block;
}


@media (max-width: 998px) {
  .card, .card-big {
    width: 98%;
  }

  .team-images img {
    height: 38px;
    margin: 4px;
  }

}

@media (max-width: 768px) {
  .no-mobile{
    display: none!important;
  }
  .team-images img {
    height: 60px;
    margin: 4px;
  }
  .logoacel{
    max-width: 70%;
  }
  .no-mobile {
    display: none;
  }
  .div-mobile-full {
    width: 100%;
  }
}

.full-div {
  width: 100%;
}
.div-90 {
  width: 90%;
}
.div-80 {
  width: 80%;
}
.div-70 {
  width: 70%;
}
.div-60 {
  width: 60%;
}
.div-50 {
  width: 50%!important;
}
.div-40 {
  width: 40%;
}
.div-33 {
  width: 33.3%;
}
.block-inline{
  display: inline-block;
}
.mat-card-border{
  border: 1px rgb(118, 118, 118) solid;
}
.mat-list-base .mat-list-item {
  font-size: 18px;
  line-height: 27px;
}
